export class CaseReasonModel {
    reasonId: number;
    name:string;
    caseReasonType:string;
    etr:number;
    etrUnit:string
    status:string;
    tatConsideration:string;
    bucketType: string
    isCustomerAllowed:boolean = false;
    isCommunicationAllowed:boolean = false;
    isDelete:boolean=false
    constructor(){
        this.name="";
        this.status=null;
        this.tatConsideration=null;
        this.caseReasonType = null;
    }
}
