import { UpdateDetails } from "./update-details";

export class Updatelistmodel {
    assignee:number;
    attachment:string;
    caseType:string;
    commentBy:string;
    createdById: number;
    createdate:string;
    filename:string;
    id:number;
    isDeleted: boolean;
    nextFollowupDate:string;
    nextFollowupTime:string;
    lastModifiedById: number;
    createDateString:string;
    suspendDate:string
    priority: string;
    reasonId: number;
    remark: string;
    remarkType: string;
    createby:string;
    resolutionId: number;
    status: string;
    etr:number;
    etrUnit:string;
    notified_enable:boolean
    ticketId: number;
    updateDetails:Array<UpdateDetails>;
    // Angular Use Only
    calculatedDay:number;
    calculatedTimeH:number;
    calculatedTimeM:number;
    calculatedTimeS:number;
    bucketType:string;
    title:string;

    /**New Fields */
    serviceAreaId:any;
    teamType:any;
    typeOfTeam:any;
    staffId:any;
    allocationType:any;
    currentAssigneeId:number;
    algorithmType:any;
    chargableType:any;
    teamId:any;
    groupId:any;
    groupName:any;
    group:any;
    amount:number;
    receipt_no:string;
    issue:string;
    solution:string;
}
