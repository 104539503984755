import { Updatelistmodel } from "./updatelistmodel";

export class CasesModel {
    caseId:number
    caseNumber:string
    createdate:Date;
    caseTitle: string;
    caseType:string;
    etr:number;
    etrUnit:any;
    notified_enable:boolean;
    caseStartedOn:string;
    caseFor:string;
    suspendDate:string
    caseOrigin:string;
    caseReasonId:number;
    caseStatus:any;
    calculatedD:number;
    calculatedTimeH:number;
    calculatedTimeM:number;
    calculatedTimeS:number;
    priority:string;
    reason:string;
    customersId:number;
    customerName:string;
    finalResolutionId:number;
    mobile:number;
    userName:string;
    remark:string;
    nextFollowupDate:string;
    finalResolvedById:number;
    finalResolutionDate:string;
    caseUpdateList:Array <Updatelistmodel>;
    receipt_no:string;
    solution:string;
    issue:string;
    amount:number;
    chargableType:string;

    isDelete:boolean=false;
    nextFollowupTime:string;
    finalResolvedByName:string;
    currentAssigneeId:number;
    currentAssigneeName:string;
   //for Angular use only
    assigneName : string;
    caseswitch : any = 'Mycases';
    serviceAreaCount:number;
    oltCount: number;
    slotCount:number;
    day:number;
    assigne:string;
    caseReasonName:string;
    caseForListing:any;
    teams:string;
    group:string;

    creationDate:string;
   cust_uname:string;
   cust_name:string;
   cust_email:string;
   cust_phone:string;
   cust_address:string;
   cust_serviceArea:string;
   cust_olt:number;
   cust_slot:number;
   cust_port:number;
   cust_onuid:number;
   caseStatusString:string;
   caseStatusLabel:string;
   firstRemark:string;
   propertyId:string;
   leaseLineId:number; 
   baseSetupId:number;
   altMobile:number;
   circuitId:number;
   title:string;
   bucketType:string;
   isSnmpEnable:boolean;
   algorithmType:any;
   teamId: number;
   teamName:string;
   groupId: number;
   groupName: string;
   allocationType: any;
   serviceAreaId:number;
   teamTypeId:number;
   teamType:string;
   staffId:number;
   longitude:any;
   latitude:any;
   multiSelected:any;
   operationDate:any;
   ticketLists:any = [];
   serviceareaid:number;
}
